// 刚刚封装的axios
import request from './axios';

// 获取首页数据
export const getInfo = (query) => {
  return request({
    url: '/hkrl-business/index/index',
    method: 'get',
    data: query,
  });
}

//二级单页类型下的一个单页（关于我们根据中文判断）
export function getSinglePage(obj) {
  return request({
    url: "/hkrl-business/index/getSinglePage/" + obj,
    method: "get",
  });
}

//文章类型_树
export function getNewsType(obj) {
  return request({
    url: "/hkrl-business/index/getNewsType/" + obj,
    method: "get",
  });
}

//文章类型 _二级_内容
export function getNews(obj) {
  return request({
    url: "/hkrl-business/index/getNews/" + obj,
    method: "get",
  });
}
//单页类型 _树
export function getSinglePageType(obj) {
  return request({
    url: "/hkrl-business/index/getSinglePageType/" + obj,
    method: "get",
  });
}

// 留言
export const saveContact = (query) => {
  return request({
    url: '/hkrl-business/index/saveContact',
    method: 'post',
    data: query,
  });
};

// 二级文章类型下的文章列表
export const getNewsPage = (query) => {
  return request({
    url: '/hkrl-business/index/getNewsPage',
    method: 'get',
    params: query,
  });
};


// 文章_详情
export function getNewsById(obj) {
  return request({
    url: "/hkrl-business/index/getNewsById/" + obj,
    method: "get",
  });
}

// 热门资讯
export const getHotNews = (query) => {
  return request({
    url: '/hkrl-business/index/getHotNews',
    method: 'get',
    params: query,
  });
};

// 文章_详情_前后
export function getNewsByIdMore(obj) {
  return request({
    url: "/hkrl-business/index/getNewsByIdMore/" + obj,
    method: "get",
  });
}

// 热力图
export function getHot(query) {
  return request({
    url: "/hkrl-business/enterpriseinfo/getHot",
    method: "get",
    params: query,
  });
}

// 企业联想
export function getPageByName(query) {
  return request({
    url: "/hkrl-business/qcc/getPageByName",
    method: "get",
    params: query,
  });
}

// 热力图数据_单个
export function getHotOne(query) {
  return request({
    url: "/hkrl-business/enterpriseinfo/getHotOne",
    method: "get",
    params: query,
  });
}

//关于我们
export function getAboultUs(obj) {
  return request({
      url: '/hkrl-business/index/getAboultUs',
      method: 'get',
      data: obj
  })
}

//新闻详情banner_列表
export function getBanner(query) {
  return request({
      url: '/hkrl-business/index/getBanner',
      method: 'get',
      params: query
  })
}