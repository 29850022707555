

export function routePortal(val, name) {
  if (name) {
    if (val == '关于我们') {
      return {
        name: 'about',
        query: {
          activeName: name
        }
      }
    } else if (val == '我们的服务') {
      return {
        name: 'service',
        query: {
          activeName: name
        }
      }
    } else if (val == '门户首页') {
      return './'
    } else if (val == '服务热力图') {
      return './hotmap'
    } else if (val == '我们的合作') {
      return {
        name: 'cooperation',
        query: {
          activeName: name
        }
      }
    } else if (val == '新闻资讯') {
      return {
        name: 'news',
        query: {
          activeName: name
        }
      }
    } else if (val == '加入我们') {
      return {
        name: 'join',
        query: {
          activeName: name
        }
      }
    }
  } else {
    if (val == '关于我们') {
      return './about'
    } else if (val == '我们的服务') {
      return './service'
    } else if (val == '门户首页') {
      return './'
    } else if (val == '我们的合作') {
      return './cooperation'
    } else if (val == '新闻资讯') {
      return './news'
    } else if (val == '加入我们') {
      return './join'
    } else if (val == '服务热力图') {
      return './hotmap'
    }
  }

}


export function enterEnterprise(val, local) {
  if (val == 'company') {
    return `https://${local}/company/#/`
  } else if (val == 'supply') {
    return `https://${local}/supply/#/`
  } else if (val == 'school') {
    return `https://${local}/school/#/`
  } else if (val == 'third_company') {
    return `https://${local}/third_company/#/`
  }
}


