<template>
  <div class="bottom">
    <div class="flex justify-center">
      <div class="fixed_width flex mt_100">
        <img src="../../assets/images/bottom/logo.png" class="logo_image" />
        <div class="row_box flex fc_FFFFFF justify-between">
          <div
            class="text-center"
            v-for="(item, index) in tab_list"
            :key="index"
          >
            <div
              class="font_weight fz_20 cursor-pointer"
              @click="handleToPage(item.title)"
            >
              {{ item.title }}
            </div>
            <div class="fz_18 fc_C6C6C6 mt_36">
              <div
                class="lh_37 cursor-pointer"
                v-for="(item1, index1) in item.children"
                :key="index1"
                @click="handleToDetail(item1.title)"
              >
                {{ item1.title }}
              </div>
            </div>
          </div>
          <div class="">
            <div class="font_weight fz_20">快速入口</div>
            <div class="mt_36">
              <el-select
                v-model="value"
                placeholder="请选择"
                @change="changeWeb()"
              >
                <el-option
                  v-for="item in options"
                  :key="item.link"
                  :label="item.title"
                  :value="item.link"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex fc_C6C6C6 fz_14 justify-center mt_110 lh_30">
      您是第
      <div class="fc_FFFFFF font_weight">{{ views }}</div>
      位访客

      <div class="ml_58">
        Copyright © 2021 惠开人才集团-<span
          class="cursor-pointer"
          @click="handleToICP"
          >苏ICP备2022006799号-1</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { getInfo } from "@/api/home";
import { routePortal } from "@/utils";
export default {
  data() {
    return {
      views: "",
      value: "",
      options: [],
      index_list: "",
      tab_list: [],
      // tab_list: [],
    };
  },
  mounted() {
    if (sessionStorage.getItem("index_list")) {
      this.index_list = JSON.parse(sessionStorage.getItem("index_list"));
      this.views = this.index_list.visit;
      this.options = this.index_list.list_links;

      // this.index_list.list_spt.forEach((item) => {
      //   item.type = 'page';
      // });
      this.tab_list = this.index_list.list_menu;
      // this.index_list.list_nt.forEach((item) => {
      //   item.type = 'news';
      //   this.tab_list.push(item);
      // });
    } else {
      getInfo().then((res) => {
        this.views = res.data.visit;
        this.options = res.data.list_links;
        // res.data.list_spt.forEach((item) => {
        //   item.type = 'page';
        // });
        this.tab_list = res.data.list_menu;
        // res.data.list_nt.forEach((item) => {
        //   item.type = 'news';
        //   this.tab_list.push(item);
        // });
      });
    }

    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?75f2d3aef2952b3e7d73ad846a42c046";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  },
  methods: {
    handleToICP() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
    changeWeb() {
      const that = this;
      that.options.forEach((item) => {
        if (item.link == that.value) {
          if (item.target == 0) {
            window.open(that.value, "_self");
          } else {
            window.open(that.value, "_blank");
          }
        }
      });
    },
    handleToPage(key) {
      this.tab_list.forEach((item) => {
        if (key == item.title) {
          this.$emit("chooseValue", item);
          this.$router.push(routePortal(key));
          sessionStorage.setItem("info", JSON.stringify(item));
        }
      });
    },
    handleToDetail(key) {
      this.tab_list.forEach((item) => {
        item.children.forEach((item1) => {
          if (key == item1.title) {
            this.$emit("chooseValue", item);
            // if (item.title != '我们的服务') {
            sessionStorage.setItem("info", JSON.stringify(item));
            // }
            this.$router.push(routePortal(item.title, key));
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bottom {
  background-color: #053972;
  // padding: 0 150px 50px 150px;
  padding-bottom: 50px;
  height: 500px;
  .logo_image {
    width: 179px;
    height: 179px;
    // margin-top: 98px;
  }
  .row_box {
    // margin-top: 98px;
    margin-left: 120px;
    width: 100%;
  }
  .ml_129 {
    margin-left: 129px;
  }
  .width_213 {
    width: 213px;
  }
  .font_weight {
    font-weight: 500;
  }
  .mt_36 {
    margin-top: 36px;
  }
}
.ml_58 {
  margin-left: 58px;
}
.mt_110 {
  margin-top: 110px;
}
.lh_37 {
  line-height: 37px;
}
::v-deep .el-select {
  width: 140px;
  // height: 30px;
}
::v-deep .el-input__inner {
  height: 30px;
  line-height: 30px;
}
::v-deep .el-input__icon {
  height: 30px;
  line-height: 30px;
}
.mt_100 {
  margin-top: 100px;
}
.lh_30 {
  line-height: 30px;
}
</style>
