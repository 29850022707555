import axios from "axios";
import { Message, Loading, MessageBox } from "element-ui";
import router from '@/router/index'

//  让请求在浏览器中允许跨域携带cookie
axios.defaults.withCredentials = true;

// 使用自定义配置新建一个 axios 实例
const service = axios.create({
  // 基础的请求地址
  baseURL: "/",
  // 设置超时时间 50s
  timeout: 50000,
});

// 添加超时后的处理（axios中需要你根据error信息来进行判断）
axios().catch((error) => {
  const { message } = error;
  if (error.code === "ECONNABORTED" && message.indexOf("timeout") > -1) {
    // 超时处理，可以直接弹出错误或者重新发起一次请求
    Message({
      type: "warning",
      message: "请求超时！请检查网络问题",
    });
    //  let newHttp= new Promise(function (resolve){
    //      resolve()
    //  })
    //  newHttp实例执行完成后会再次执行
    //  返回一个promise实例，同时重新发起请求，config请求配置，包扩请求头和请求参数
    //  return newHttp.then(function (){
    //      return  axios.create({baseURL: 'https://some-domain.com/api/',timeout: 5000});
    //  })
  }
  // 若不是超时,则返回未错误信息
  return Promise.reject(error);
});

// 请求拦截器，例如请求的时候在头部加上请求的token
let loading = null
service.interceptors.request.use(
  (config) => {
    loading = Loading.service({ text: '拼命加载中' })
    if (sessionStorage.getItem('access_token')) {
      if (!config.headers.Authorization) {
        config.headers.Authorization = 'Bearer ' + sessionStorage.getItem('access_token');
      }

    }

    return config; //  有且必须有一个config对象被返回
  },
  (error) => {
    //   对请求错误做些什么
    console.log(error);
    return Promise.reject();
  }
);

// 响应拦截器,例如判断服务器返回的状态，400，500啥的，其实超时可以写到这里面来，我分开写了一个比较直观

service.interceptors.response.use((res) => {
  loading.close()
  if (res.data.code == 0) {
    return Promise.resolve(res.data);
  } else if (res.data.code == 1) {
    Message({
      type: "warning",
      message: res.data.msg,
    });
    return Promise.reject(res)
  } else {
    return Promise.resolve(res.data);
  }
}, error => {
  loading.close()

  if (error.response.status === 424) {
    MessageBox.confirm('令牌状态已过期，请点击重新登录', '系统提示', {
      confirmButtonText: '重新登录',
      cancelButtonText: '取消',
      type: 'warning'
    }
    ).then(() => {
      router.push({
        name: '/'
      })
      window.location.reload()
    }).catch(() => {
    });
    return
  }

  if (error.response.status === 426) {
    Message({
      type: "error",
      message: error.response.data.msg,
    });
    return
  }

  Message({
    type: "warning",
    message: '系统未知错误,请反馈给管理员',
  });
  return Promise.reject(error)
});

export default service;
