<template>
  <div class="header">
    <div class="flex justify-center">
      <div class="fixed_width align-center">
        <div class="flex justify-between">
          <div class="flex">
            <div class="flex align-center">
              <img
                class="logo_image"
                src="../../assets/images/header/logo.png"
              />
              <img
                class="hkrl_image"
                src="../../assets/images/header/hkrl.png"
              />
            </div>

            <el-menu
              :default-active="name"
              class="el-menu-demo tab_box"
              mode="horizontal"
              @select="handleSelect"
            >
              <el-menu-item
                v-for="(item, index) in tab_list"
                :key="index"
                :index="item.title"
                >{{ item.title }}
              </el-menu-item>
            </el-menu>
          </div>
          <div class="flex">
            <div
              class="flex cursor-pointer align-center"
              @click="handleToJob()"
              @mouseenter="job_active = true"
              @mouseleave="job_active = false"
            >
              <img
                v-if="job_active == false"
                class="phone_image"
                src="../../assets/images/header/phone.png"
              />
              <img
                v-else
                class="phone_image"
                src="../../assets/images/header/phone_active.png"
              />
              <div
                :class="job_active == true ? 'fc_2C8EFF' : ''"
                class="fc_111C2F fz_18"
              >
                找工作
              </div>
            </div>

            <div class="line"></div>

            <div
              class="flex cursor-pointer align-center"
              @click="handleToRecruit()"
              @mouseenter="recruit_active = true"
              @mouseleave="recruit_active = false"
            >
              <img
                v-if="recruit_active == false"
                class="computer_image"
                src="../../assets/images/header/computer.png"
              />
              <img
                v-else
                class="computer_image"
                src="../../assets/images/header/computer_active.png"
              />
              <div
                :class="recruit_active == true ? 'fc_2C8EFF' : ''"
                class="fc_111C2F fz_18"
              >
                去招聘
              </div>
            </div>
            <el-popover placement="bottom-end" trigger="hover" width="">
              <!-- <img
                src="../../assets/images/home/serviceNumber.jpg"
                class="code_iamge"
              /> -->
              <img
                class="code_iamge"
                src="../../assets/images/home/subscriptionNumber1.png"
              />
              <div
                slot="reference"
                @mouseenter="code_active = true"
                @mouseleave="code_active = false"
              >
                <img
                  v-if="code_active == false"
                  class="qrcode_image"
                  src="../../assets/images/header/qrcode.png"
                />
                <img
                  v-else
                  class="qrcode_image"
                  src="../../assets/images/header/qrcode_active.png"
                />
              </div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from "@/api/home";
import { routePortal } from "@/utils";

export default {
  props: {
    activeName: {
      type: String,
    },
  },
  data() {
    return {
      // activeName: '关于我们',
      tab_list: [],
      new_tab_list: [],
      index_list: [],
      name: "",
      job_active: false,
      recruit_active: false,
      code_active: false,
      local: "",
    };
  },
  mounted() {
    this.local = window.location.host;
    this.name = this.activeName;
    if (sessionStorage.getItem("index_list")) {
      // this.tab_list = JSON.parse(sessionStorage.getItem('tab_list'));
      this.index_list = JSON.parse(sessionStorage.getItem("index_list"));
      // this.index_list.list_spt.forEach((item) => {
      //   item.type = 'page';
      // });
      this.tab_list = this.index_list.list_menu;
      this.tab_list.unshift({
        id: 0,
        title: "门户首页",
      });
      this.tab_list.push(
        {
          id: 6,
          title: "服务热力图",
        },
        {
          id: 7,
          title: "考试中心",
        }
      );
      this.new_tab_list = JSON.parse(JSON.stringify(this.tab_list));
      this.tab_list.splice(6, 1);
      console.log("tab_list", this.tab_list);
      console.log("new_tab_list", this.new_tab_list);
      sessionStorage.setItem(
        "idChildren",
        JSON.stringify(this.new_tab_list[6].children)
      );
    } else {
      getInfo().then((res) => {
        // res.data.list_spt.forEach((item) => {
        //   item.type = 'page';
        // });
        this.tab_list = res.data.list_menu;
        this.tab_list.unshift({
          id: 0,
          title: "门户首页",
        });
        this.tab_list.push(
          {
            id: 6,
            title: "服务热力图",
          },
          {
            id: 7,
            title: "考试中心",
          }
        );
        this.new_tab_list = JSON.parse(JSON.stringify(this.tab_list));
        this.tab_list.splice(6, 1);
        console.log("tab_list", this.tab_list);
        console.log("new_tab_list", this.new_tab_list);
        sessionStorage.setItem(
          "idChildren",
          JSON.stringify(this.new_tab_list[6].children)
        );
      });
    }
  },
  methods: {
    handleSelect(key) {
      if (key == "考试中心") {
        window.open("https://ks.wxhkhr.com/", "_blank");
        return;
      }
      this.tab_list.forEach((item) => {
        if (key == item.title) {
          this.$emit("chooseValue", item);
          this.$router.push(routePortal(key));
          sessionStorage.setItem("info", JSON.stringify(item));
        }
      });
    },
    handleToJob() {
      this.$router.push("./seekJob");
    },
    handleToRecruit() {
      window.open(`https://${this.local}/company/#/`);
    },
  },
};
</script>
<style>
/* el-popover生成的div不在当前组件之内，甚至不在App.vue组件的div内，
他和App.vue组件的div平级，所以需要设置全局style。
这里也不能用穿透符>>>因为el-popover不在当前组件之内 */
.el-popover {
  /* min-width: 12vw; // 两个二维码 */
  min-width: 6vw;
}
</style>
<style lang="scss" scoped>
.header {
  height: 69px;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 99;
  font-size: 18px;
  // opacity: 0.8;
  // background-color: rgba(255, 255, 255, 0.8);
  background-color: #fff;
  font-weight: 500;

  .logo_image {
    width: 39px;
    height: 39px;
    // margin-left: 20px;
    // margin-top: 9px;
  }

  .hkrl_image {
    width: 90px;
    height: 30px;
    margin-left: 9px;
    // margin-top: 16px;
  }

  .tab_box {
    margin-left: 42px;
    // margin-top: 10px;
  }

  .qrcode_image {
    width: 19px;
    height: 19px;
    // margin-right: 280px;
    // margin-top: 26px;
    margin: 25px 0px 25px 19px;
  }

  .phone_image {
    width: 11px;
    height: 19px;
    // margin-top: 26px;
    margin-right: 8px;
  }

  .line {
    width: 2px;
    height: 18px;
    background: #e3e3e3;
    margin: 25px 15px;
  }

  .computer_image {
    width: 22px;
    height: 19px;
    // margin-top: 26px;
    margin-right: 8px;
  }
}

/*去掉el-tab-pane底部灰色线条*/
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}

::v-deep .el-tabs__active-bar {
  background-color: #4690f7;
  width: 92px !important;
  height: 3px;
}

::v-deep .el-tabs__item {
  font-size: 18px;
  width: 92px;
  text-align: center;
  margin-bottom: 13px;
  color: #111c2f;
  font-weight: 400;
  padding: 0;
  margin-top: 15px;
}

::v-deep .el-tabs__item.is-active {
  color: #2c8eff;
}

.fc_2C8EFF {
  color: #2c8eff;
}

.fc_111C2F {
  color: #111c2f;
}

.el-menu {
  background-color: transparent;
  border: 0;
}

.el-menu-item.is-active {
  color: #2c8eff;
}

.el-menu-item {
  font-size: 18px;
  color: #111c2f;
}

.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
  background-color: transparent;
  border-bottom: 3px solid #2c8eff;
  color: #2c8eff;
}

.el-menu-item:hover {
  border-bottom: 3px solid #2c8eff !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}

.el-menu--horizontal > .el-menu-item {
  height: 69px;
  line-height: 69px;
}

.fc_2C8EFF {
  color: #2c8eff !important;
}

.code_iamge {
  width: 120px;
  height: 120px;
}

.el-menu-item {
  padding: 0;
  margin: 0 20px;
}

.el-popover__reference {
  display: flex;
  align-items: center;
}
</style>
